import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import Headings from "@components/Headings";
import Image, { ImagePlaceholder } from "@components/Image";

import Icons from "@icons";
import mediaqueries from "@styles/media";
import { IArticle, IAuthor } from "@types";

import ArticleAuthors from "./Article.Authors";
import ArticleBio from "./Article.Bio";

interface ArticleFooterProps {
  article: IArticle;
  authors: IAuthor[];
}

const ArticleFooter: React.FC<ArticleFooterProps> = ({ article, authors }) => {
  const hasCoAUthors = authors.length > 1;
  const hasFooterImage =
    article.Footer &&
    Object.keys(article.Footer.full).length !== 0 &&
    article.Footer.full.constructor === Object;

  return (
    <Footer>
      <ArticleFooterContainer>
        {article.tags && (
          <TagList>
            {article.tags.map((tag) => (
              <li key={tag}>
                <Link to={`/taxonomy?tag=${tag}`}>{tag}</Link>
              </li>
            ))}
          </TagList>
        )}
      </ArticleFooterContainer>
      <ArticleBioContainer>
        <ArticleBio author={authors[0]} />
      </ArticleBioContainer>
    </Footer>
  );
};

export default ArticleFooter;

const Footer = styled.div`
  ${(p) => mediaqueries.phablet`
    &::before {
      content: "";
      width: 100%;
      height: 19px;
      background: ${p.theme.colors.primary};
      position: absolute;
      left: 0;
      top: 0;
      transition: ${p.theme.colorModeTransition};
    }

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      background: ${p.theme.colors.background};
      position: absolute;
      left: 0;
      top: 10px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      transition: ${p.theme.colorModeTransition};
    }
  `}
`;

const ArticleFooterContainer = styled.div`
  position: relative;
  z-index: 10;
  margin: 50px auto 50px;
  max-width: 680px;

  ${mediaqueries.desktop`
    max-width: calc(507px + 53px);
    margin: 100px auto 70px;
  `}

  ${mediaqueries.tablet`
    margin: 100px auto 70px;
    max-width: 480px;
  `}

  ${mediaqueries.phablet`
    margin: 170px auto 180px;
  `}

  @media screen and (max-height: 700px) {
    margin: 100px auto;
  }
`;

const TagList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    list-style: none;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  a {
    color: ${(p) => p.theme.colors.primary};
    background: ${(p) => p.theme.colors.hover};
    font-weight: 700;
    border-radius: 15px;
    padding: 5px 12px;
    transition: all 0.2s;
    &:hover {
      color: ${(p) => p.theme.colors.background};
      background: ${(p) => p.theme.colors.accent};
    }
  }
`;

const ArticleBioContainer = styled.div`
  position: relative;
  z-index: 10;
  margin: 50px auto 50px;
  max-width: 680px;
  padding: 40px 20px;
  border-top: 1px solid ${(p) => p.theme.colors.horizontalRule};
  border-bottom: 1px solid ${(p) => p.theme.colors.horizontalRule};

  ${mediaqueries.desktop`
    max-width: calc(507px + 53px);
    margin: 100px auto 70px;
  `}

  ${mediaqueries.tablet`
    margin: 100px auto 70px;
    max-width: 480px;
  `}

  ${mediaqueries.phablet`
    margin: 170px auto 180px;
  `}

  @media screen and (max-height: 700px) {
    margin: 100px auto;
  }
`;
