import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import Image from "@components/Image";
import { IAuthor } from "@types";

const ArticleBio: React.FC<IAuthor> = ({ author }) => {
  return (
    <ArticleBioContainer>
      <ArticleBioAvatar
        as={author.authorsPage ? Link : "div"}
        to={author.slug}
        data-a11y="false"
        aria-label="Author's ArticleBio"
      >
        <ArticleBioAvatarInner>
          <RoundedImage src={author.avatar.medium} alt="author image" />
        </ArticleBioAvatarInner>
      </ArticleBioAvatar>
      <ArticleBioTextContainer>
        <ArticleBioName>{author.name}</ArticleBioName>
        <ArticleBioText dangerouslySetInnerHTML={{ __html: author.bio }} />
      </ArticleBioTextContainer>
    </ArticleBioContainer>
  );
};

export default ArticleBio;

const ArticleBioContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -10px;
`;

const ArticleBioAvatar = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  margin: 10px 26px 10px 10px;

  &::after {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border: 2px solid ${(p) => p.theme.colors.accent};
  }
`;

const RoundedImage = styled(Image)`
  border-radius: 50%;
`;

const ArticleBioAvatarInner = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  overflow: hidden;
`;

const ArticleBioTextContainer = styled.div`
  font-size: 14px;
  line-height: 1.45;
  color: ${(p) => p.theme.colors.primary};
`;

const ArticleBioName = styled.strong`
  font-size: 14px;
  line-height: 1.45;
  color: ${(p) => p.theme.colors.primary};
`;

const ArticleBioText = styled.p`
  font-size: 14px;
  line-height: 1.45;
  color: ${(p) => p.theme.colors.primary};

  a {
    color: ${(p) => p.theme.colors.grey};
    text-decoration: underline;
  }
`;
