import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

/* 
Template:

<Data type="g" value="0.46" n="665" l="0.25" u="0.66" p="0.001"></Data>; 

*/

const r2d = (r) => {
  return (2 * r) / Math.sqrt(1 - Math.pow(r, 2));
};
const g2d = (g, n) => {
  return g / (1 - 3 / (4 * n - 1));
};
const or2d = (or) => {
  return (Math.log(or) * Math.sqrt(3)) / Math.PI;
};
const ci2p = (u, l) => {
  var se = Math.abs(u - l) / (2 * 1.96);
  var z = Math.abs(u + l) / 2 / se;
  return Math.exp(-0.717 * z - 0.416 * z ** 2);
};

const Data = (props) => {
  var { type, value, u, l, n, p } = props;
  value = parseFloat(value);
  u = parseFloat(u);
  l = parseFloat(l);
  n = parseInt(n);
  p = parseFloat(p);
  var d, d_p;

  if (type === "d") {
    d = value;
    d_p = p || ci2p(u, l);
  } else if (type === "g") {
    d = g2d(value, n);
    d_p = p || ci2p(g2d(u, n), g2d(l, n));
  } else if (type === "r") {
    d = r2d(value);
    d_p = p || ci2p(r2d(u), r2d(l));
  } else if (type === "or") {
    d = or2d(value);
    d_p = p || ci2p(or2d(u), or2d(l));
  } else {
    d = 0.0;
    d_p = 0.0;
  }

  if (isNaN(d_p)) {
    d_p = "p=不明";
  } else if (d_p <= 0.0001) {
    d_p = "p<0.0001";
  } else if (d_p <= 0.001) {
    d_p = "p<0.001";
  } else if (d_p <= 0.01) {
    d_p = "p<0.01";
  } else if (d_p <= 0.05) {
    d_p = "p<0.05";
  } else if (d_p <= 0.1) {
    d_p = "p<0.1";
  } else {
    d_p = "p=" + d_p.toFixed(3);
  }

  return (
    <Popup>
      <span className="tooltip">
        <i className="fa fa-table" aria-hidden="true"></i>
        <span className="tooltiptext">
          <span>
            <span className="bold">元データ</span>
            <br />
            {type}={value.toFixed(2)}
            {u ? (
              <span>
                <br />
                CI=[{l.toFixed(2)}, {u.toFixed(2)}]
              </span>
            ) : null}
            {p ? (
              <span>
                <br />
                p={p}
              </span>
            ) : null}
          </span>
          <br />
          {d ? (
            <span>
              <span className="bold">換算後</span>
              <br />
              d={d.toFixed(2)}
              <br />
              {d_p}
            </span>
          ) : null}
        </span>
      </span>
    </Popup>
  );
};

Data.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  u: PropTypes.string,
  l: PropTypes.string,
  n: PropTypes.string,
  p: PropTypes.string,
};

export default Data;

const Popup = styled.span`
  .tooltip {
    position: relative;
    display: inline-block;
    padding: 5px;
    cursor: pointer;
    font-weight: normal;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: #fff;
    color: #000;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    top: 2em;
    box-shadow: 0 0 8px rgb(0 0 0 / 30%);
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .bold {
    font-weight: 700;
  }
`;
