import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Product = (props) => {
  const { imageLink, name, developer, keyword, links } = props;

  // let k = encodeURIComponent(encodeURIComponent(keyword));
  let k = encodeURIComponent(keyword);

  let linksList = links.split(",");
  let linksDict = {
    amazon: {
      label: "Amazon",
      link: `https://www.amazon.co.jp/s?k=${k}`,
    },
    rakuten: {
      label: "楽天市場",
      link: `https://hb.afl.rakuten.co.jp/hgc/1c30db75.d2d32751.1c30db76.52167d1f/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2F${k}%2F%3Ff%3D1%26grp%3Dproduct`,
    },
    yahoo: {
      label: "Yahooショッピング",
      link: `https://ck.jp.ap.valuecommerce.com/servlet/referral?sid=3486387&pid=886113642&vc_url=https%3A%2F%2Fshopping.yahoo.co.jp%2Fsearch%3Fp%3D${k}`,
    },
    iherb: {
      label: "iHerb",
      link: `https://jp.iherb.com/search?kw=${k}&rcode=BAH3377`,
    },
    myprotein: {
      label: "MyProtein",
      link: `https://www.myprotein.jp/elysium.search?search=${k}`,
    },
  };

  return (
    <Rinker>
      <div className="yyi-rinker-contents yyi-rinker-img-m">
        <div className="yyi-rinker-image">
          <img className="yyi-rinker-main-img" src={imageLink} alt={name} />
        </div>
        <div className="yyi-rinker-info">
          <div className="yyi-rinker-title">
            <p>{name}</p>
          </div>
          <div className="yyi-rinker-detail">
            <span className="yyi-rinker-developer">{developer}</span>
          </div>
          <ul className="yyi-rinker-links">
            {linksList.map(
              (item) =>
                item in linksDict && (
                  <li className={`${item}link`} key={item}>
                    <a
                      className="yyi-rinker-link yyi-rinker-tracking"
                      rel="nofollow"
                      href={linksDict[item].link}
                    >
                      {linksDict[item].label}
                    </a>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </Rinker>
  );
};

Product.propTypes = {
  name: PropTypes.string.isRequired,
  imageLink: PropTypes.string.isRequired,
  developer: PropTypes.string,
  keyword: PropTypes.string,
  links: PropTypes.string,
};

export default Product;

const Rinker = styled.div`
  .yyi-rinker-contents {
    border: 3px solid #eee;
    text-align: left;
    padding: 25px;
    margin: 0 auto 35px;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    box-sizing: border-box;
  }

  .yyi-rinker-contents a {
    border: none;
  }

  .yyi-rinker-contents .yyi-rinker-image {
    margin: 0 3% 0 0 !important;
    padding: 0;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    max-width: 120px;
    max-height: 120px;
    width: 25%;
  }

  .yyi-rinker-contents .yyi-rinker-image .yyi-rinker-main-img {
    margin: 0 !important;
    max-height: 100%;
  }

  .yyi-rinker-contents .yyi-rinker-title a,
  .yyi-rinker-contents .yyi-rinker-title p {
    font-size: 16px;
    color: ${(p) => p.theme.colors.primary};
    padding-bottom: 10px;
    font-weight: bold;
    line-height: 1.5em !important;
    max-height: 3em;
    overflow: hidden;
  }

  .yyi-rinker-contents .yyi-rinker-detail {
    font-size: 12px !important;
    color: ${(p) => p.theme.colors.primary};
  }

  .yyi-rinker-contents .yyi-rinker-detail a {
    text-decoration: underline;
  }

  .yyi-rinker-contents .yyi-rinker-detail .price-box {
    color: ${(p) => p.theme.colors.primary};
  }

  .yyi-rinker-contents .yyi-rinker-detail .free-text {
    color: ${(p) => p.theme.colors.primary};
  }

  .yyi-rinker-contents ul.yyi-rinker-links {
    border: none;
    list-style-type: none;
    display: inline-flex;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 15px auto 0;
    padding: 0;
    width: 100%;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li {
    content: "";
    position: static;
    margin: 0 10px 10px 0;
    padding: 0;
    border-radius: 2px;
    border: none;
    box-shadow: 0 6px 12px -5px rgba(0, 0, 0, 0.3);
    -ms-flex: 0 0 auto;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li:before {
    content: "";
    position: static;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li:hover {
    box-shadow: 0 5px 8px -5px rgba(0, 0, 0, 0.3);
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.amazonlink {
    background: #f6a306;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li a {
    position: relative;
    display: block;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.9em;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 18px;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li a:hover {
    border: none;
    opacity: 0.7;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li a:before {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    text-align: center;
    border: none;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.amazonlink {
    order: 20;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.amazonlink a:before {
    content: "";
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.amazonkindlelink {
    background: #666;
    order: 15;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.amazonkindlelink a:before {
    content: "";
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.rakutenlink {
    background: #cf4944;
    order: 30;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.rakutenlink a:before {
    content: "";
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.yahoolink {
    background: #51a7e8;
    order: 40;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.yahoolink a:before {
    content: "";
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.iherblink {
    background: #458500;
    order: 10;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.myproteinlink {
    background: #008190;
    order: 5;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.freelink1 {
    background: #4072b3;
    order: 10;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.freelink2 {
    background: #4dc0b2;
    order: 50;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.freelink3 {
    background: #6f63ad;
    order: 15;
  }

  .yyi-rinker-contents ul.yyi-rinker-links li.freelink4 {
    background: #63ad87;
    order: 55;
  }

  .yyi-rinker-contents ul.yyi-rinker-links img,
  .yyi-rinker-contents .yyi-rinker-title img,
  .yyi-rinker-contents .yyi-rinker-image a + img {
    display: none;
  }

  .yyi-rinker-detail .credit {
    font-size: 75%;
  }

  ul.yyi-rinker-links li::before {
    background-color: transparent;
  }

  @media all and (max-width: 420px) and (min-width: 321px) {
    body .yyi-rinker-contents ul.yyi-rinker-links li a {
      padding: 0 0 0 15px;
    }

    .yyi-rinker-contents ul.yyi-rinker-links {
      -webkit-flex-flow: column;
      flex-direction: column;
    }

    .yyi-rinker-img-s .yyi-rinker-image {
      width: 75px;
      min-width: 75px;
    }

    .yyi-rinker-img-m .yyi-rinker-image {
      width: 125px;
      min-width: 125px;
    }

    .yyi-rinker-img-l .yyi-rinker-image {
      width: 150px;
      min-width: 150px;
    }
  }

  @media all and (max-width: 320px) {
    .yyi-rinker-contents ul.yyi-rinker-links li a {
      padding: 0 0 0 7px;
    }

    .yyi-rinker-contents ul.yyi-rinker-links {
      -webkit-flex-flow: column;
      flex-direction: column;
    }

    .yyi-rinker-img-s .yyi-rinker-image {
      width: 75px;
      min-width: 75px;
    }

    .yyi-rinker-img-m .yyi-rinker-image {
      width: 100px;
      min-width: 100px;
    }

    .yyi-rinker-img-l .yyi-rinker-image {
      width: 125px;
      min-width: 125px;
    }
  }

  body .yyi-rinker-contents ul.yyi-rinker-links li {
    list-style: none;
  }

  body .yyi-rinker-contents ul.yyi-rinker-links li:before {
    position: absolute;
  }

  .yyi-rinker-contents p {
    padding: 0;
  }

  .yyi-rinker-contents img {
    margin-bottom: 0;
  }
`;
