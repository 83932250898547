import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import gplay from "../../assets/images/gplay_ja.png";
import itune from "../../assets/images/itune_ja.svg";

const App = (props) => {
  const { imageLink, name, developer, googleplay, applestore, price } = props;

  return (
    <AppReach>
      <div className="appreach">
        <img className="appreach__icon" src={imageLink} alt={name} />
        <div className="appreach__detail">
          <p className="appreach__name">{name}</p>
          <p className="appreach__info">
            <span className="appreach__developper">{developer}</span>
            <span className="appreach__price">{price}</span>
          </p>
        </div>
        <div className="appreach__links">
          {applestore && (
            <a className="appreach__aslink" href={applestore}>
              <img src={itune} alt="applestore" />
            </a>
          )}
          {googleplay && (
            <a className="appreach__gplink" href={googleplay}>
              <img src={gplay} alt="googleplay" />
            </a>
          )}
        </div>
      </div>
    </AppReach>
  );
};

App.propTypes = {
  imageLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  developer: PropTypes.string.isRequired,
  googleplay: PropTypes.string,
  applestore: PropTypes.string,
  price: PropTypes.string.isRequired,
};

export default App;

const AppReach = styled.div`
  .appreach {
    border: 3px solid #eee;
    text-align: left;
    padding: 25px;
    margin: 0 auto 35px;
    border-radius: 2px;
    overflow: hidden;
  }

  .appreach:after {
    content: "";
    display: block;
    clear: both;
  }

  .appreach img,
  .appreach p {
    margin: 0;
    padding: 0;
  }

  .appreach a:after {
    display: none;
  }

  .appreach__icon {
    float: left;
    border-radius: 10%;
    overflow: hidden;
    margin: 0 3% 0 0 !important;
    width: 25% !important;
    height: auto !important;
    max-width: 120px !important;
  }

  .appreach__detail {
    display: inline-block;
    font-size: 20px;
    line-height: 1.5;
    width: 72%;
    max-width: 72%;
  }

  .appreach__detail:after {
    content: "";
    display: block;
    clear: both;
  }

  .appreach__detail p.appreach__name {
    font-size: 16px;
    color: ${(p) => p.theme.colors.primary};
    padding-bottom: 10px;
    font-weight: bold;
    line-height: 1.5em !important;
    max-height: 3em;
    overflow: hidden;
  }

  .appreach__info {
    font-size: 12px !important;
    color: ${(p) => p.theme.colors.primary};
  }

  .appreach__info a {
    color: ${(p) => p.theme.colors.primary};
  }

  .appreach__developper,
  .appreach__price {
    margin-right: 0.5em;
  }

  .appreach__links {
    float: left;
    height: 40px;
    margin-top: 15px;
    white-space: nowrap;
  }

  .appreach__aslink img {
    margin: 0 10px 0 0 !important;
    height: 40px;
    width: 135px;
  }

  .appreach__gplink img {
    margin: 0 !important;
    height: 40px;
    width: 134.5px;
  }

  @media only screen and (max-width: 786px) {
    .appreach {
      margin: 20px 0;
    }

    .appreach__info {
      font-size: 11px !important;
    }

    p.appreach__name {
      font-size: 15px;
    }
  }
`;
